<template lang="pug">
  v-row
    slot
    v-col.pt-0.pb-0(cols="12")
      filter-autocomplete.mb-2(
        v-model="formData.licenseType"
        :list="LICENSE_TYPES"
        label="License type"
        :error="formErrors.hasError('licenseType')"
        :error-messages="formErrors.fields.licenseType"
        item-text="name"
        item-value="slug"
        testName="license-types"
        :show-clear-btn="true"
      )
      filter-autocomplete(
        v-model="formData.location"
        :getItems="getExamLocation"
        label="Exam Location"
        :error="formErrors.hasError('location')"
        :error-messages="formErrors.fields.location"
        testName="exam-location"
      )
    v-col.pt-0.pb-0(v-if="isCreateExam" cols="12")
      div(v-if="formData.rangeMode")
        fieldDatepicker(
          v-model="formData.dateFrom"
          label="Exam Date Start"
          :error="formErrors.hasError('examDateFrom')"
          :error-messages="formErrors.fields.examDateFrom"
        )
        fieldDatepicker(
          v-model="formData.dateTo"
          label="Exam Date End"
          :error="formErrors.hasError('examDateTo')"
          :error-messages="formErrors.fields.examDateTo"
        )
      div(v-else)
        v-text-field(
          v-model.number="formData.exam_date_future_days"
          label="Add days"
          type="number"
          min="0"
          :error="formErrors.hasError('exam_date_future_days')"
          :error-messages="formErrors.fields.exam_date_future_days"
        )
    v-col.pt-0.pb-0(v-else cols="12")
      fieldDatepicker(
        v-model="formData.date"
        label="Exam Date"
        :error="formErrors.hasError('examDate')"
        :error-messages="formErrors.fields.examDate"
      )
    v-col.pt-0.pb-0(cols="6")
      timepicker(
        :key="`${showing}timeFrom`"
        v-model="formData.timeFrom"
        label="Time from"
        :hide-details="false"
        :error="formErrors.hasError('timeFrom')"
        :error-messages="formErrors.fields.timeFrom"
        data-test="exam-time-from"
      )
    v-col.pt-0.pb-0(cols="6")
      timepicker(
        :key="`${showing}timeFrom`"
        v-model="formData.timeTo"
        label="Time to"
        :hide-details="false"
        :error="formErrors.hasError('timeTo')"
        :error-messages="formErrors.fields.timeTo"
        data-test="exam-time-to"
      )
</template>

<script>
import { mapActions } from 'vuex'
import { convertToDefaultBackendFormat } from '@/util'
import featureFlagsMixin from '@/mixins/featureFlags.mixin'
import {LICENSE_TYPES} from "@/util/const";

export default {
  name: 'ExamBody',

  mixins: [featureFlagsMixin],

  props: {
    formData: Object,
    formErrors: Object,
    showing: Boolean,
    isCreateExam: Boolean
  },

  data: () => ({
    LICENSE_TYPES,
    currentDate: convertToDefaultBackendFormat(new Date(Date.now())),
  }),

  methods: {
    ...mapActions({
      getExamLocation: 'crmExamLocation/list',
    }),

    updateVal(val, field) {
      this.formData[field] = val
    }
  },

  components: {
    datepicker: () => import('@/app/admin/components/CrmDatepicker.vue'),
    timepicker: () => import('@/components/global/Timepicker.vue'),
    filterAutocomplete: () => import('@/components/global/FilterAutocomplete.vue'),
    fieldDatepicker: () => import('@/components/global/FieldDatepicker.vue')
  }
}
</script>
